(function(){

	const matchHeights_setHeight = (el, val) => {
		if (typeof val === "function") {
			val = val();
		}
		if (typeof val === "string") {
			el.style.height = val;
		}
		else {
			el.style.height = val + "px";
		}
	}


	const matchHeights = elemsOrSelector => {
		let currentTallest = 0
		let currentRowStart = 0
		let rowDivs = []
		let currentDiv
		let topPosition = 0

		const collection = ( typeof elemsOrSelector === "string" ) ? document.querySelectorAll(elemsOrSelector) : elemsOrSelector

		collection.forEach((el,i) => {
			if ( window.outerWidth < 680 ) {
				el.style.height = ''
				return
			}
			el.style.height = "auto"
			topPosition = el.offsetTop
			if ( currentRowStart !== topPosition ){
				for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
					matchHeights_setHeight( rowDivs[currentDiv], currentTallest )
				}
				rowDivs.length = 0
				currentRowStart = topPosition
				currentTallest = parseFloat(window.getComputedStyle(el, null).height.replace("px", ""))
				rowDivs.push(el)
			}
			else {
				rowDivs.push(el)
				currentTallest = (currentTallest < parseFloat(window.getComputedStyle(el, null).height.replace("px", ""))) ? (parseFloat(window.getComputedStyle(el, null).height.replace("px", ""))) : (currentTallest)
			}
			for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
				matchHeights_setHeight(rowDivs[currentDiv], currentTallest)
			}
		})
	}

	const _doMatchHeights = () => {
		const mhGroups = {}
		document.querySelectorAll('[data-mh]').forEach(el => {
			let group = el.dataset.mh
			if ( !group || group === "" ) {
				group = "all"
			}
			if ( !mhGroups.hasOwnProperty(group) ) {
				mhGroups[group] = []
			}
			mhGroups[group].push(el)
		})

		document.querySelectorAll('.mh').forEach(el => {
			if ( el.dataset.hasOwnProperty('mh') ) {
				return
			}
			const group = "all"
			if ( !mhGroups.hasOwnProperty(group) ) {
				mhGroups[group] = []
			}
			mhGroups[group].push(el)
		})

		Object.keys(mhGroups).forEach(key => {
			matchHeights( mhGroups[key] )
		})
	}

	window.addEventListener('load', () => {
		_doMatchHeights()
	})
	let resizeDelay;
	window.addEventListener('resize', () => {
		clearTimeout(resizeDelay);
		resizeDelay = setTimeout(function(){
			_doMatchHeights()
		}, 10)
	})
	let lazyDelay;
	document.addEventListener('lazy_img_loaded', () => {
		clearTimeout(lazyDelay);
		lazyDelay = setTimeout(function(){
			_doMatchHeights()
		}, 10)
	})

})()
